<template>
  <div class="h-100">
    <router-view />
  </div>
</template>

<style lang="scss">
  #app {
    font-family: Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #cccccc;
  }

  nav {
  }
</style>
